export const listToMap = (list, keyName = 'key', labelName = 'value') => {
  return list.reduce((res, cur) => {
    res[cur[keyName]] = cur[labelName];
    return res;
  }, {});
};

const tabsEnumSet = [
  {
    name: '设置',
    icon: 'sz',
    url: 'car'
  },
  {
    name: '客服中心',
    icon: 'kfzx',
    url: 'car'
  }
];

const tabsEnumSet1 = [
  // {
  //   name: '运费代收授权',
  //   icon: 'shouquan',
  //   url: 'mine/authorization'
  // },
  {
    name: '我的余额',
    icon: 'yueqianbao',
    url: 'mine/balance'
  }
];

const steps = ['车牌号', '行驶证', '道路运输证', '其它信息'];

const palteEnum = [
  {
    key: '2',
    value: '黄色',
    color: '#FFBD02'
  },
  {
    key: '1',
    value: '蓝色',
    color: '#1464ff'
  },
  {
    key: '5',
    value: '绿色',
    color: '#21b952'
  },
  {
    key: '93',
    value: '黄绿色',
    color: ['#FFBD02', '#21b952'],
    mixinColor: true
  },
  {
    key: '3',
    value: '黑色'
  },
  {
    key: '4',
    value: '白色'
  },
  {
    key: '91',
    value: '农黄色'
  },
  {
    key: '92',
    value: '农绿色'
  },
  {
    key: '94',
    value: '渐变绿'
  },
  {
    key: '9',
    value: '其他'
  }
];

const plateEnumMap = listToMap(palteEnum);

const carsTypeEnum = [
  {
    key: 'E',
    value: '天然气',
    color: '#0076ff'
  },
  {
    key: 'B',
    value: '柴油',
    color: '#fc9412'
  },
  {
    key: 'A',
    value: '汽油',
    color: '#1F984B'
  },
  {
    key: 'C',
    value: '电'
  },
  {
    key: 'D',
    value: '混合油'
  },
  {
    key: 'F',
    value: '液化石油气'
  },
  {
    key: 'L',
    value: '甲醇'
  },
  {
    key: 'M',
    value: '乙醇'
  },
  {
    key: 'N',
    value: '太阳能'
  },
  {
    key: 'O',
    value: '混合动力'
  },
  {
    key: 'Y',
    value: '无'
  },
  {
    key: 'Z',
    value: '其他'
  }
];

const carsTypeEnumMap = listToMap(carsTypeEnum);

const bankList = {
  // 银行卡及主权重色枚举值
  ABC: {
    filename: 'ABC.png',
    backgroundColor: '0, 146, 115',
    backgroundEnd: '0, 146, 115'
  },
  ANTB: {
    filename: 'ANTB.png',
    backgroundColor: '157, 35, 48',
    backgroundEnd: '157, 35, 48, 0.6'
  },
  ARCU: {
    filename: 'ARCU.png',
    backgroundColor: '219, 38, 29',
    backgroundEnd: '219, 38, 29, 0.6'
  },
  ASCB: {
    filename: 'ASCB.png',
    backgroundColor: '219, 38, 29',
    backgroundEnd: '219, 38, 29, 0.6'
  },
  AYCB: {
    filename: 'AYCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BANKWF: {
    filename: 'BANKWF.png',
    backgroundColor: '0, 146, 115',
    backgroundEnd: '0, 146, 115, 0.6'
  },
  BGB: {
    filename: 'BGB.png',
    backgroundColor: '0, 124, 194',
    backgroundEnd: '0, 124, 194, 0.6'
  },
  BHB: {
    filename: 'BHB.png',
    backgroundColor: '9, 55, 132',
    backgroundEnd: '9, 55, 132, 0.6'
  },
  BJBANK: {
    filename: 'BJBANK.png',
    backgroundColor: '228, 31, 32',
    backgroundEnd: '228, 31, 32, 0.6'
  },
  BJRCB: {
    filename: 'BJRCB.png',
    backgroundColor: '184, 0, 26',
    backgroundEnd: '184, 0, 26, 0.6'
  },
  BOC: {
    filename: 'BOC.png',
    backgroundColor: '226, 42, 40',
    backgroundEnd: '226, 42, 40, 0.6'
  },
  BOCD: {
    filename: 'BOCD.png',
    backgroundColor: '184, 0, 26',
    backgroundEnd: '184, 0, 26, 0.6'
  },
  BOCY: {
    filename: 'BOCY.png',
    backgroundColor: '0, 91, 172',
    backgroundEnd: '0, 91, 172, 0.6'
  },
  BOD: {
    filename: 'BOD.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BODD: {
    filename: 'BODD.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BOHAIB: {
    filename: 'BOHAIB.png',
    backgroundColor: '41, 22, 111',
    backgroundEnd: '41, 22, 111, 0.6'
  },
  BOJZ: {
    filename: 'BOJZ.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BOP: {
    filename: 'BOP.png',
    backgroundColor: '184, 28, 32',
    backgroundEnd: '184, 28, 32, 0.6'
  },
  BOQH: {
    filename: 'BOQH.png',
    backgroundColor: '151, 29, 88',
    backgroundEnd: '151, 29, 88, 0.6'
  },
  BOSZ: {
    filename: 'BOSZ.png',
    backgroundColor: '105, 213, 3',
    backgroundEnd: '105, 213, 3, 0.6'
  },
  BOYK: {
    filename: 'BOYK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BOZK: {
    filename: 'BOZK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  BSB: {
    filename: 'BSB.png',
    backgroundColor: '105, 213, 3',
    backgroundEnd: '105, 213, 3, 0.6'
  },
  BZMD: {
    filename: 'BZMD.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  CBBQS: {
    filename: 'CBBQS.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  CBKF: {
    filename: 'CBKF.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  CCB: {
    filename: 'CCB.png',
    backgroundColor: '0, 59, 143',
    backgroundEnd: '0, 59, 143, 0.6'
  },
  CCQTGB: {
    filename: 'CCQTGB.png',
    backgroundColor: '36, 33, 28',
    backgroundEnd: '36, 33, 28, 0.6'
  },
  CDB: {
    filename: 'CDB.png',
    backgroundColor: '188, 140, 68',
    backgroundEnd: '188, 140, 68, 0.6'
  },
  CDCB: {
    filename: 'CDCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  CDRCB: {
    filename: 'CDRCB.png',
    backgroundColor: '229, 1, 16',
    backgroundEnd: '229, 1, 16, 0.6'
  },
  CEB: {
    filename: 'CEB.png',
    backgroundColor: '31, 26, 23',
    backgroundEnd: '31, 26, 23, 0.6'
  },
  CGNB: {
    filename: 'CGNB.png',
    backgroundColor: '31, 26, 23',
    backgroundEnd: '31, 26, 23, 0.6'
  },
  CIB: {
    filename: 'CIB.png',
    backgroundColor: '0, 65, 134',
    backgroundEnd: '0, 65, 134, 0.6'
  },
  CITIC: {
    filename: 'CITIC.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  CMB: {
    filename: 'CMB.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  CMBC: {
    filename: 'CMBC.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  COMM: {
    filename: 'COMM.png',
    backgroundColor: '29, 32, 136',
    backgroundEnd: '29, 32, 136, 0.6'
  },
  CQBANK: {
    filename: 'CQBANK.png',
    backgroundColor: '0, 171, 77',
    backgroundEnd: '0, 171, 77, 0.6'
  },
  CRCBANK: {
    filename: 'CRCBANK.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  CSCB: {
    filename: 'CSCB.png',
    backgroundColor: '0, 171, 77',
    backgroundEnd: '0, 171, 77, 0.6'
  },
  CSRCB: {
    filename: 'CSRCB.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  CZBANK: {
    filename: 'CZBANK.png',
    backgroundColor: '223, 183, 24',
    backgroundEnd: '223, 183, 24, 0.6'
  },
  CZCB: {
    filename: 'CZCB.png',
    backgroundColor: '167, 33, 38',
    backgroundEnd: '167, 33, 38, 0.6'
  },
  CZRCB: {
    filename: 'CZRCB.png',
    backgroundColor: '173, 11, 42',
    backgroundEnd: '173, 11, 42, 0.6'
  },
  DAQINGB: {
    filename: 'DAQINGB.png',
    backgroundColor: '249, 190, 0',
    backgroundEnd: '249, 190, 0, 0.6'
  },
  DLB: {
    filename: 'DLB.png',
    backgroundColor: '212, 22, 24',
    backgroundEnd: '212, 22, 24, 0.6'
  },
  DRCBCL: {
    filename: 'DRCBCL.png',
    backgroundColor: '178, 0, 0',
    backgroundEnd: '178, 0, 0, 0.6'
  },
  DYCB: {
    filename: 'DYCB.png',
    backgroundColor: '214, 10, 33',
    backgroundEnd: '214, 10, 33, 0.6'
  },
  DYCCB: {
    filename: 'DYCCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  DZBANK: {
    filename: 'DZBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  EGBANK: {
    filename: 'EGBANK.png',
    backgroundColor: '0, 61, 126',
    backgroundEnd: '0, 61, 126, 0.6'
  },
  FDB: {
    filename: 'FDB.png',
    backgroundColor: '157, 35, 48',
    backgroundEnd: '157, 35, 48, 0.6'
  },
  FJHXBC: {
    filename: 'FJHXBC.png',
    backgroundColor: '1, 81, 152',
    backgroundEnd: '1, 81, 152, 0.6'
  },
  FSCB: {
    filename: 'FSCB.png',
    backgroundColor: '207, 1, 11',
    backgroundEnd: '207, 1, 11, 0.6'
  },
  FXCB: {
    filename: 'FXCB.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  GCB: {
    filename: 'GCB.png',
    backgroundColor: '218, 37, 30',
    backgroundEnd: '218, 37, 30, 0.6'
  },
  GDB: {
    filename: 'GDB.png',
    backgroundColor: '1, 81, 152',
    backgroundEnd: '1, 81, 152, 0.6'
  },
  GDRCC: {
    filename: 'GDRCC.png',
    backgroundColor: '68, 119, 52',
    backgroundEnd: '68, 119, 52, 0.6'
  },
  GLBANK: {
    filename: 'GLBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  GRCB: {
    filename: 'GRCB.png',
    backgroundColor: '1, 81, 152',
    backgroundEnd: '1, 81, 152, 0.6'
  },
  GSRCU: {
    filename: 'GSRCU.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  GXRCU: {
    filename: 'GXRCU.png',
    backgroundColor: '0, 68, 27',
    backgroundEnd: '0, 68, 27, 0.6'
  },
  GYCB: {
    filename: 'GYCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  GZB: {
    filename: 'GZB.png',
    backgroundColor: '231, 0, 20',
    backgroundEnd: '231, 0, 20, 0.6'
  },
  GZRCU: {
    filename: 'GZRCU.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  H3CB: {
    filename: 'H3CB.png',
    backgroundColor: '207, 1, 14',
    backgroundEnd: '207, 1, 14, 0.6'
  },
  HANABANK: {
    filename: 'HANABANK.png',
    backgroundColor: '111, 58, 104',
    backgroundEnd: '111, 58, 104, 0.6'
  },
  HBC: {
    filename: 'HBC.png',
    backgroundColor: '207, 1, 14',
    backgroundEnd: '207, 1, 14, 0.6'
  },
  HBHSBANK: {
    filename: 'HBHSBANK.png',
    backgroundColor: '111, 58, 104',
    backgroundEnd: '111, 58, 104, 0.6'
  },
  HBRCU: {
    filename: 'HBRCU.png',
    backgroundColor: '68, 119, 52',
    backgroundEnd: '68, 119, 52, 0.6'
  },
  HBYCBANK: {
    filename: 'HBYCBANK.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  HDBANK: {
    filename: 'HDBANK.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  HKB: {
    filename: 'HKB.png',
    backgroundColor: '0, 134, 179',
    backgroundEnd: '0, 134, 179, 0.6'
  },
  HKBEA: {
    filename: 'HKBEA.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  HNRCC: {
    filename: 'HNRCC.png',
    backgroundColor: '68, 119, 52',
    backgroundEnd: '68, 119, 52, 0.6'
  },
  HNRCU: {
    filename: 'HNRCU.png',
    backgroundColor: '182, 0, 0',
    backgroundEnd: '182, 0, 0, 0.6'
  },
  HRXJB: {
    filename: 'HRXJB.png',
    backgroundColor: '200, 0, 11',
    backgroundEnd: '200, 0, 11, 0.6'
  },
  HSBANK: {
    filename: 'HSBANK.png',
    backgroundColor: '196, 37, 28',
    backgroundEnd: '196, 37, 28, 0.6'
  },
  HSBC: {
    filename: 'HSBC.png',
    backgroundColor: '237, 42, 46',
    backgroundEnd: '237, 42, 46, 0.6'
  },
  HSBK: {
    filename: 'HSBK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  HURCB: {
    filename: 'HURCB.png',
    backgroundColor: '237, 42, 46',
    backgroundEnd: '237, 42, 46, 0.6'
  },
  HXBANK: {
    filename: 'HXBANK.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  HZCB: {
    filename: 'HZCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  HZCCB: {
    filename: 'HZCCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  ICBC: {
    filename: 'ICBC.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  JHBANK: {
    filename: 'JHBANK.png',
    backgroundColor: '200, 21, 43',
    backgroundEnd: '200, 21, 43, 0.6'
  },
  JINCHB: {
    filename: 'JINCHB.png',
    backgroundColor: '200, 20, 47',
    backgroundEnd: '200, 20, 47, 0.6'
  },
  JJBANK: {
    filename: 'JJBANK.png',
    backgroundColor: '206, 0, 12',
    backgroundEnd: '206, 0, 12, 0.6'
  },
  JLBANK: {
    filename: 'JLBANK.png',
    backgroundColor: '220, 8, 23',
    backgroundEnd: '220, 8, 23, 0.6'
  },
  JLRCU: {
    filename: 'JLRCU.png',
    backgroundColor: '206, 0, 12',
    backgroundEnd: '206, 0, 12, 0.6'
  },
  JNBANK: {
    filename: 'JNBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  JRCB: {
    filename: 'JRCB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  JSB: {
    filename: 'JSB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  JSBANK: {
    filename: 'JSBANK.png',
    backgroundColor: '0, 101, 179',
    backgroundEnd: '0, 101, 179, 0.6'
  },
  JSRCU: {
    filename: 'JSRCU.png',
    backgroundColor: '209, 164, 83',
    backgroundEnd: '209, 164, 83, 0.6'
  },
  JXBANK: {
    filename: 'JXBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  JXRCU: {
    filename: 'JXRCU.png',
    backgroundColor: '68, 119, 52',
    backgroundEnd: '68, 119, 52, 0.6'
  },
  JZBANK: {
    filename: 'JZBANK.png',
    backgroundColor: '167, 34, 39',
    backgroundEnd: '167, 34, 39, 0.6'
  },
  KLB: {
    filename: 'KLB.png',
    backgroundColor: '195, 20, 27',
    backgroundEnd: '195, 20, 27, 0.6'
  },
  KORLABANK: {
    filename: 'KORLABANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  KSRB: {
    filename: 'KSRB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  LANGFB: {
    filename: 'LANGFB.png',
    backgroundColor: '2, 103, 183',
    backgroundEnd: '2, 103, 183, 0.6'
  },
  LSBANK: {
    filename: 'LSBANK.png',
    backgroundColor: '206, 0, 12',
    backgroundEnd: '206, 0, 12, 0.6'
  },
  LSBC: {
    filename: 'LSBC.png',
    backgroundColor: '200, 39, 31',
    backgroundEnd: '200, 39, 31, 0.6'
  },
  LSCCB: {
    filename: 'LSCCB.png',
    backgroundColor: '228, 0, 17',
    backgroundEnd: '228, 0, 17, 0.6'
  },
  LYBANK: {
    filename: 'LYBANK.png',
    backgroundColor: '0, 43, 171',
    backgroundEnd: '0, 43, 171, 0.6'
  },
  LYCB: {
    filename: 'LYCB.png',
    backgroundColor: '199, 0, 11',
    backgroundEnd: '199, 0, 11, 0.6'
  },
  LZYH: {
    filename: 'LZYH.png',
    backgroundColor: '31, 48, 128',
    backgroundEnd: '31, 48, 128, 0.6'
  },
  MTBANK: {
    filename: 'MTBANK.png',
    backgroundColor: '31, 48, 128',
    backgroundEnd: '31, 48, 128, 0.6'
  },
  NBBANK: {
    filename: 'NBBANK.png',
    backgroundColor: '242, 197, 10',
    backgroundEnd: '242, 197, 10, 0.6'
  },
  NBYZ: {
    filename: 'NBYZ.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  NCB: {
    filename: 'NCB.png',
    backgroundColor: '199, 0, 11',
    backgroundEnd: '199, 0, 11, 0.6'
  },
  NHB: {
    filename: 'NHB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  NHQS: {
    filename: 'NHQS.png',
    backgroundColor: '68, 119, 52',
    backgroundEnd: '68, 119, 52, 0.6'
  },
  NJCB: {
    filename: 'NJCB.png',
    backgroundColor: '230, 0, 18',
    backgroundEnd: '230, 0, 18, 0.6'
  },
  NXBANK: {
    filename: 'NXBANK.png',
    backgroundColor: '231, 0, 18',
    backgroundEnd: '231, 0, 18, 0.6'
  },
  NXRCU: {
    filename: 'NXRCU.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  NYNB: {
    filename: 'NYNB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  ORBANK: {
    filename: 'ORBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  PSBC: {
    filename: 'PSBC.png',
    backgroundColor: '0, 126, 61',
    backgroundEnd: '0, 126, 61, 0.6'
  },
  QDCCB: {
    filename: 'QDCCB.png',
    backgroundColor: '230, 0, 0',
    backgroundEnd: '230, 0, 0, 0.6'
  },
  QLBANK: {
    filename: 'QLBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  SCCB: {
    filename: 'SCCB.png',
    backgroundColor: '253, 253, 253',
    backgroundEnd: '253, 253, 253, 0.6'
  },
  SCRCU: {
    filename: 'SCRCU.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  SDEB: {
    filename: 'SDEB.png',
    backgroundColor: '2, 21, 139',
    backgroundEnd: '2, 21, 139, 0.6'
  },
  SDRCU: {
    filename: 'SDRCU.png',
    backgroundColor: '235, 28, 36',
    backgroundEnd: '235, 28, 36, 0.6'
  },
  SHBANK: {
    filename: 'SHBANK.png',
    backgroundColor: '250, 157, 0',
    backgroundEnd: '250, 157, 0, 0.6'
  },
  SHRCB: {
    filename: 'SHRCB.png',
    backgroundColor: '13, 36, 129',
    backgroundEnd: '13, 36, 129, 0.6'
  },
  SJBANK: {
    filename: 'SJBANK.png',
    backgroundColor: '0, 16, 171',
    backgroundEnd: '0, 16, 171, 0.6'
  },
  SMXB: {
    filename: 'SMXB.png',
    backgroundColor: '253, 253, 253',
    backgroundEnd: '253, 253, 253, 0.6'
  },
  SPABANK: {
    filename: 'SPABANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  SPDB: {
    filename: 'SPDB.png',
    backgroundColor: '29, 32, 136',
    backgroundEnd: '29, 32, 136, 0.6'
  },
  SRBANK: {
    filename: 'SRBANK.png',
    backgroundColor: '254, 153, 0',
    backgroundEnd: '254, 153, 0, 0.6'
  },
  SRCB: {
    filename: 'SRCB.png',
    backgroundColor: '0, 106, 176',
    backgroundEnd: '0, 106, 176, 0.6'
  },
  SXCB: {
    filename: 'SXCB.png',
    backgroundColor: '29, 32, 135',
    backgroundEnd: '29, 32, 135, 0.6'
  },
  SXRCCU: {
    filename: 'SXRCCU.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  SZSBK: {
    filename: 'SZSBK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  TACCB: {
    filename: 'TACCB.png',
    backgroundColor: '0, 52, 154',
    backgroundEnd: '0, 52, 154, 0.6'
  },
  TCCB: {
    filename: 'TCCB.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  TCRCB: {
    filename: 'TCRCB.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  TRCB: {
    filename: 'TRCB.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  TZCB: {
    filename: 'TZCB.png',
    backgroundColor: '85, 194, 214',
    backgroundEnd: '85, 194, 214, 0.6'
  },
  URMQCCB: {
    filename: 'URMQCCB.png',
    backgroundColor: '246, 170, 0',
    backgroundEnd: '246, 170, 0, 0.6'
  },
  WHCCB: {
    filename: 'WHCCB.png',
    backgroundColor: '0, 104, 183',
    backgroundEnd: '0, 104, 183, 0.6'
  },
  WHRCB: {
    filename: 'WHRCB.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  WJRCB: {
    filename: 'WJRCB.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  WRCB: {
    filename: 'WRCB.png',
    backgroundColor: '254, 0, 0',
    backgroundEnd: '254, 0, 0, 0.6'
  },
  WZCB: {
    filename: 'WZCB.png',
    backgroundColor: '246, 173, 44',
    backgroundEnd: '246, 173, 44, 0.6'
  },
  XABANK: {
    filename: 'XABANK.png',
    backgroundColor: '0, 40, 89',
    backgroundEnd: '0, 40, 89, 0.6'
  },
  XCYH: {
    filename: 'XCYH.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  XLBANK: {
    filename: 'XLBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  XTB: {
    filename: 'XTB.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  XXBANK: {
    filename: 'XXBANK.png',
    backgroundColor: '255, 255, 255',
    backgroundEnd: '255, 255, 255, 0.6'
  },
  XYBANK: {
    filename: 'XYBANK.png',
    backgroundColor: '218, 37, 28',
    backgroundEnd: '218, 37, 28, 0.6'
  },
  YBCCB: {
    filename: 'YBCCB.png',
    backgroundColor: '29, 32, 136',
    backgroundEnd: '29, 32, 136, 0.6'
  },
  YDRCB: {
    filename: 'YDRCB.png',
    backgroundColor: '0, 154, 68',
    backgroundEnd: '0, 154, 68, 0.6'
  },
  YNRCC: {
    filename: 'YNRCC.png',
    backgroundColor: '255, 0, 0',
    backgroundEnd: '255, 0, 0, 0.6'
  },
  YQCCB: {
    filename: 'YQCCB.png',
    backgroundColor: '202, 31, 39',
    backgroundEnd: '202, 31, 39, 0.6'
  },
  YXCCB: {
    filename: 'YXCCB.png',
    backgroundColor: '217, 37, 27',
    backgroundEnd: '217, 37, 27, 0.6'
  },
  ZBCB: {
    filename: 'ZBCB.png',
    backgroundColor: '29, 32, 136',
    backgroundEnd: '29, 32, 136, 0.6'
  },
  ZGCCB: {
    filename: 'ZGCCB.png',
    backgroundColor: '255, 0, 0',
    backgroundEnd: '255, 0, 0, 0.6'
  },
  ZJKCCB: {
    filename: 'ZJKCCB.png',
    backgroundColor: '231, 0, 18',
    backgroundEnd: '231, 0, 18, 0.6'
  },
  ZJNX: {
    filename: 'ZJNX.png',
    backgroundColor: '0, 78, 161',
    backgroundEnd: '0, 78, 161, 0.6'
  },
  ZJTLCB: {
    filename: 'ZJTLCB.png',
    backgroundColor: '231, 0, 18',
    backgroundEnd: '231, 0, 18, 0.6'
  },
  ZRCBANK: {
    filename: 'ZRCBANK.png',
    backgroundColor: '255, 178, 2',
    backgroundEnd: '255, 178, 2, 0.6'
  },
  ZYCBANK: {
    filename: 'ZYCBANK.png',
    backgroundColor: '231, 0, 18',
    backgroundEnd: '231, 0, 18, 0.6'
  },
  ZZBANK: {
    filename: 'ZZBANK.png',
    backgroundColor: '218, 37, 30',
    backgroundEnd: '218, 37, 30, 0.6'
  },
  DEFAULT: {
    filename: 'Applets%20Logo_siji-b%403x.png',
    backgroundColor: '42,119,246',
    backgroundEnd: '42,119,246, 0.6'
  },
  SPAB_SETTLE: {
    filename: 'pingan.png',
    backgroundColor: '42,119,246',
    backgroundEnd: '42,119,246, 0.6'
  }
};

const getBankCssObj = code => {
  if (!code || !bankList[code]) {
    return bankList['DEFAULT'];
  }
  return bankList[code];
};

const ICON_CDN = 'https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu/';
const getFullBankIcon = filename => {
  return `${ICON_CDN}${filename}`;
};
const getBankIconByCode = code => {
  const filename = getBankCssObj(code)?.filename;
  return getFullBankIcon(filename);
};

export {
  tabsEnumSet,
  tabsEnumSet1,
  steps,
  palteEnum,
  bankList,
  carsTypeEnum,
  plateEnumMap,
  carsTypeEnumMap,
  getBankCssObj,
  ICON_CDN,
  getFullBankIcon,
  getBankIconByCode
};
